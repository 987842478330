.add-antecedent-card {
  /* max-width: 50%; */
  margin: auto;
}

.add-antecedent-card .ant-input {
  max-width: 50%;
}

.add-antecedent-card .ant-card-head-title {
  color: #fff;
  text-align: center;
}

.add-antecedent-card .ant-card-head {
  background: #6394f9;
}

.dmi-treatment-reminder {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
}

.inclusion-form .ant-form-item-label  {
  font-weight: 600;
  white-space: initial;
  margin-bottom: 14px;
  height: auto !important;
  text-align: initial;
}

.inclusion-form .ant-form-item-label > label {
  font-weight: 600;
  white-space: initial;
  margin-bottom: 14px;
  height: auto !important;
  text-align: initial;
}