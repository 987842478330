

.patient-list-item {
    cursor: pointer;
    padding: 10px 30px;
  }
  
.patient-list-item:hover {
    background-color: #f0f2f5;
  }
  
  .bloodsample-card .ant-card-body {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }

  .first-dose {
    /* border: 1px solid #FF8718 !important; */
  }
  
  /* .ant-card {
    min-width: 100%;
  }
  .ant-card-bordered{
        min-width: 100%;
  } */

  .formWidth{
    min-width: 100%;
  }