/* .ant-form{
    max-width: 53%;

} 
.ant-form-horizontal{
    max-width: 53%;
}

.ant-descriptions-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

#drugForm .ant-descriptions-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}