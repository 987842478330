.stepsHeader > div:nth-child(1){
   min-width: 100px; 
}
.stepsHeader > div:nth-child(2){
    min-width: 100px; 
 }
 .stepsHeader > div:nth-child(3){
    min-width: 100px; 
 }
 .stepsHeader > div:nth-child(4){
    min-width: 100px; 
 }
 .stepsHeader > div:nth-child(5){
    min-width: 100px; 
 }

 .ant-card-body{
     padding-top: 24px !important;
     padding-left: 10px !important;
     padding-right: 10px !important;
     padding-bottom: 24px !important;

 }