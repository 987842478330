
.field-action-icon {
    font-size: 18px;
}

.color-green {
    color: #52c41a!important;
}


.color-red {
    color: #ff4d4f!important;
}

.color-blue {
    color: #1890ff!important;
}


.color-yellow {
    color:#faad14!important;
}

