.header-top {
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all .3s cubic-bezier(.78,.14,.15,.86);
}

.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
  flex: 1;
  justify-content: flex-end;
}

#logo {
  height: 50px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
  padding-top: 10px;
}

#logo img {
  position: relative;
  height: 40px;
}

.icon-logo {
  display: none;
}

img.icon-logo {
  height: 32px;
  position: relative;
  bottom: 13px;
  left: 9px;
}
