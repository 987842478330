
.field-action-icon {
    font-size: 18px;
}

.color-green {
    color: #52c41a!important;
}


.color-red {
    color: #ff4d4f!important;
}

.color-blue {
    color: #1890ff!important;
}


.color-yellow {
    color:#faad14!important;
}


.add-antecedent-card {
  /* max-width: 50%; */
  margin: auto;
}

.add-antecedent-card .ant-input {
  max-width: 50%;
}

.add-antecedent-card .ant-card-head-title {
  color: #fff;
  text-align: center;
}

.add-antecedent-card .ant-card-head {
  background: #6394f9;
}

.dmi-treatment-reminder {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
}

.inclusion-form .ant-form-item-label  {
  font-weight: 600;
  white-space: initial;
  margin-bottom: 14px;
  height: auto !important;
  text-align: initial;
}

.inclusion-form .ant-form-item-label > label {
  font-weight: 600;
  white-space: initial;
  margin-bottom: 14px;
  height: auto !important;
  text-align: initial;
}
.message--sent {
    position: absolute;
    right: 3.15rem;
    bottom: -1.075rem;
    display: flex;
    align-items: center;
  }
  
  .message--sent svg {
    margin-left: 0.25rem;
  }
  
  .message--sent-date {
    position: absolute;
    left: 3.75rem;
    bottom: -1.075rem;
  }
  
  .message-text-contact {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 1rem;
    margin: -0.625rem 0 0.25rem 0.75rem;
    background-color: #f0f2f5;
    word-wrap: anywhere;
  }
  
  .message:not(:first-child) {
    margin-top: 2rem;
  }
  .message:first-child {
    margin-top: 1rem;
  }
  
  .message-text-user {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    margin: -0.625rem 0.75rem 0.25rem 0;
    font-size: 1rem;
    background-color: #1890ff;
    color: #fff;
    word-wrap: anywhere;
  }
  
  .message-user-receiver {
    flex-direction: row-reverse;
  }
  
  .message {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
  }
  
  .message > .ant-avatar.ant-avatar-icon {
    flex-shrink: 0;
  }
  
 
#selected-patient .ant-descriptions-view {
    overflow: unset;
}
/* .ant-form{
    max-width: 53%;

} 
.ant-form-horizontal{
    max-width: 53%;
}

.ant-descriptions-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

#drugForm .ant-descriptions-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.stepsHeader > div:nth-child(1){
   min-width: 100px; 
}
.stepsHeader > div:nth-child(2){
    min-width: 100px; 
 }
 .stepsHeader > div:nth-child(3){
    min-width: 100px; 
 }
 .stepsHeader > div:nth-child(4){
    min-width: 100px; 
 }
 .stepsHeader > div:nth-child(5){
    min-width: 100px; 
 }

 .ant-card-body{
     padding-top: 24px !important;
     padding-left: 10px !important;
     padding-right: 10px !important;
     padding-bottom: 24px !important;

 }


.patient-list-item {
    cursor: pointer;
    padding: 10px 30px;
  }
  
.patient-list-item:hover {
    background-color: #f0f2f5;
  }
  
  .bloodsample-card .ant-card-body {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }

  .first-dose {
    /* border: 1px solid #FF8718 !important; */
  }
  
  /* .ant-card {
    min-width: 100%;
  }
  .ant-card-bordered{
        min-width: 100%;
  } */

  .formWidth{
    min-width: 100%;
  }
.patient-list-item  {
    cursor: pointer;
}

.symptoms-list-item:not(:first-of-type) {
    margin-top: 1rem;
}
.header-top {
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all .3s cubic-bezier(.78,.14,.15,.86);
}

.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
  flex: 1 1;
  justify-content: flex-end;
}

#logo {
  height: 50px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
  padding-top: 10px;
}

#logo img {
  position: relative;
  height: 40px;
}

.icon-logo {
  display: none;
}

img.icon-logo {
  height: 32px;
  position: relative;
  bottom: 13px;
  left: 9px;
}

:root {
  --main-bg-color: #f0f2f5;
}

.app-content {
  min-height: 86vh;
}

.header-title {
  color: #001529 !important;
  text-align: center;
  line-height: inherit !important;
}

.section-content {
  margin: 50px;
}

.layout-bg {
  background: #ffff;
}

.login-card{
  width: 350px;
  margin: 10% auto 0;
  opacity: 0.9;
}

.success-button {
  color: #5fc114;
  background-color: inherit;
  border: 1px solid #5fc114;
}

.success-button:hover {
  color: #83c84e;
  background-color: inherit;
  border: 1px solid #83c84e;
}

.icon-button {
  margin-right: 5px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.center-button {
  display: block;
  margin: auto;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

#unpadded-card .ant-card-body {
  padding: 0;
}

/* OVERRIDES */

.ant-layout-sider-trigger {
  background: #fff;
  color: #002140;
}

.ant-avatar {
  background: #1890ff;
}

.side-bar-menu .ant-menu-item-icon {
  font-size: 16px;
}


:root {
  --animate-duration: 2s !important
}
